import { render, staticRenderFns } from "./AvailsReport.vue?vue&type=template&id=32ff513f&scoped=true&"
import script from "./AvailsReport.vue?vue&type=script&lang=js&"
export * from "./AvailsReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ff513f",
  null
  
)

export default component.exports