<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
	<BookingGridNew/>
    
 </div>
</template>	
<script>
import {HTTP, HTTPMP, asrAPI, opsAPI, bngAPI, fwAPI, invAPI, setReload, showError, myLocale} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBG, getBGColor, isDarkMode } from '@/AppStyle.js';
import BookingGridNew from '@/components/BookingGridAvails';
export default {
  name: 'GFW_AVAILS',
   
  components : {
	BookingGridNew
  },
  data () {
    return {
      
		
      }
  },
  methods: {
    
  },
  created() { 
    
    initAppMode();
  },
  watch: {
  
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    //
		  })
 	}
}
</script>

<style scoped>

</style> 